import { useRouter } from "solito/router";

import { HelpCircle } from "@tamagui/lucide-icons";

import {
  CalendarText,
  Paragraph,
  Paragraph2,
  XStack,
  YStack,
  hairline,
  styled,
} from "@medbillai/ui";

import { billUrl } from "@medbillai/utils";

import { SingularEvents, singularService } from "../../../lib/singular";
import { type NormalizedEob } from "../utils/types";
import { formatCostTotal } from "../utils/utils";
import { BillListItemIcon } from "./shared/BillListItemIcon";

type BillListItemProps = {
  eob: NormalizedEob;
  borderHide?: boolean;
  flashListView?: boolean;
  selected?: boolean;
  webHomePage?: boolean;
  flagged?: boolean;
};

const SubTitleParagraph = styled(Paragraph2, {
  theme: "alt1",
  whiteSpace: "nowrap",
  lineHeight: 16,
});

export const BillListItem = ({
  eob,
  borderHide = false,
  flashListView = false,
  selected,
  webHomePage = false,
  flagged = false,
}: BillListItemProps) => {
  const { push } = useRouter();
  const openBill = () => {
    singularService.event(SingularEvents.openBill);
    push(
      billUrl(
        eob.resourceId,
        eob.location || "Unknown Location",
        eob.decision || "",
      ),
    );
  };

  const mainTitle = eob.title ? eob.title : eob.location ?? "-";
  const subTitle = mainTitle !== eob.location ? eob.location : "No Description";

  return (
    <YStack
      px="$4"
      onPress={openBill}
      cur="pointer"
      pressStyle={{
        backgroundColor: "$backgroundPress",
      }}
      backgroundColor={selected ? "$backgroundPress" : "transparent"}
      hoverStyle={{ backgroundColor: "$backgroundPress" }}
      br={webHomePage ? "$4" : "$0"}
    >
      <XStack
        ai="center"
        jc="flex-start"
        gap="$4"
        py="$4"
        flex={1}
        borderTopWidth={borderHide ? 0 : 0.5}
        borderBottomWidth={flashListView ? 0.5 : 0}
        borderColor="$strokeSecondary"
        backgroundColor="transparent"
      >
        {flagged ? (
          <XStack
            theme="orange"
            alignItems="center"
            br={12}
            jc="center"
            bg="$orange3"
            width="$4"
            height="$4"
            borderWidth={hairline}
            borderColor="$orange4"
          >
            <HelpCircle color="$orange13" size="$1" strokeWidth={1.75} />
          </XStack>
        ) : (
          <BillListItemIcon
            status={webHomePage ? null : eob.decision}
            iconProps={
              "unread" in eob && eob.unread
                ? {
                    size: "$1",
                    color: "$color",
                    fill: "$color",
                  }
                : undefined
            }
          />
        )}
        <YStack
          alignItems="flex-end"
          justifyContent="flex-end"
          gap="$1"
          flex={1}
          height="$4"
        >
          <XStack gap="$5" ai="baseline" jc="space-between" f={1} width="100%">
            <Paragraph
              overflow="hidden"
              textOverflow="ellipsis"
              f={1}
              numberOfLines={1}
            >
              {mainTitle}
            </Paragraph>
            <Paragraph minWidth="auto" whiteSpace="nowrap">
              {formatCostTotal(eob.totalCost, "$0")}
            </Paragraph>
          </XStack>
          <XStack gap="$4" ai="center" jc="space-between" f={1} width="100%">
            <SubTitleParagraph
              numberOfLines={1}
              overflow="hidden"
              textOverflow="ellipsis"
              f={1}
            >
              {subTitle}
            </SubTitleParagraph>
            <CalendarText
              date={eob.serviceDate || ""}
              element={props => <SubTitleParagraph {...props} />}
              utc={true}
            />
          </XStack>
        </YStack>
      </XStack>
    </YStack>
  );
};

import dayjs from "dayjs";

import {
  type GetInsuranceLinksQuery,
  flexpa__EndpointMode,
  flexpa__LinkDeactivatedReason,
  ingestion__IngestionStatus,
} from "@medbillai/graphql-types";

import { analyticsService } from "../../../lib/analytics";
import type { ErrorState } from "../../../lib/insurance-link/insuranceStore";
import type { Endpoint } from "../../../lib/insurance-link/types";
import type { AlertDialog } from "../../../provider/notifications";
import type { InsuranceLinkStatus } from "../components/InsuranceCard";

export const INSURANCE_CARD_POLL_INTERVAL_MS = 2000;

export const FLEXPA_LINK_WAIT_TIME_MINUTES = 10;
export const WEB_AGENT_INGESTION_WAIT_TIME_MINUTES = 60;
export const WEB_AGENT_CRAWL_WAIT_TIME_MINUTES = 15;

export const resultUnavailableKey = "resultUnavailable";
export const resultUnavailablePlaceholder: Endpoint = {
  __typename: "flexpa__Endpoint_Type",
  displayName: resultUnavailableKey,
  id: resultUnavailableKey,
  slug: "PlaceHolder",
  isActive: true,
  organization: {
    __typename: "integration__Organization_Type",
    id: "PlaceHolder",
    displayName: "PlaceHolder",
    slug: "PlaceHolder",
  },
  flexpaId: "PlaceHolder",
  mode: flexpa__EndpointMode.LIVE,
  name: "PlaceHolder",
};

export const isValidFunction = (func: unknown): func is () => void =>
  typeof func === "function";

export const closeInsurancePreemptiveAlert = (
  redirect: () => void,
): AlertDialog => {
  const alert: AlertDialog = {
    title: "Exit connecting insurance?",
    message: "Are you sure you want to exit? Your progress will not be saved.",
    acceptText: "Exit",
    cancellable: true,
    onAccept: () => {
      redirect();
    },
  };
  return alert;
};

export const restartInsuranceAlert = (redirect: () => void): AlertDialog => {
  const alert: AlertDialog = {
    title: "Restart connecting insurance?",
    message: "Are you sure you want to exit? Your progress will not be saved.",
    acceptText: "Back",
    cancellable: true,
    onAccept: () => {
      redirect();
    },
  };
  return alert;
};

export const getWebLinkStatus = (
  link: NonNullable<
    NonNullable<GetInsuranceLinksQuery["me"]>["webAgentLinks"]
  >[0],
): InsuranceLinkStatus => {
  if (link.deactivatedAt) {
    return {
      _tag: "DEACTIVATED",
      id: link.id,
    };
  }
  if (!link.latestIngestion) {
    // This logic is mirrored in useNormalizedEobs.tsx any update here should be
    // reflected there, because the gql queries are different, they will likely
    // remain separate functions

    // We show loading spinner for 15 minutes while crawl might be still running
    if (
      dayjs().diff(link.latestExecution?.createdAt, "minutes") >
      WEB_AGENT_CRAWL_WAIT_TIME_MINUTES
    ) {
      return {
        _tag: "CRAWL_TIMEOUT",
      };
    }
    return {
      _tag: "CRAWL_IN_PROGRESS",
    };
  }
  switch (link.latestIngestion.status) {
    case ingestion__IngestionStatus.IN_PROGRESS:
      // If the ingestion has been in progress for a long time, it's possible
      // that something went wrong. We show a failure message in this case to
      // prevent a forever spinner.
      if (
        dayjs().diff(link.latestIngestion.startedAt, "minutes") >
        WEB_AGENT_INGESTION_WAIT_TIME_MINUTES
      ) {
        return {
          _tag: "FAILED",
        };
      }
      return {
        _tag: "INGESTION_IN_PROGRESS",
        ingested: link.latestIngestion.resources || [],
      };
    case ingestion__IngestionStatus.COMPLETED: {
      return {
        _tag: "COMPLETED",
        endedAt: link.latestIngestion.endedAt
          ? dayjs(link.latestIngestion.endedAt).toDate()
          : new Date(),
      };
    }
    case ingestion__IngestionStatus.PARTIAL: {
      return {
        _tag: "PARTIAL",
        endedAt: link.latestIngestion.endedAt
          ? dayjs(link.latestIngestion.endedAt).toDate()
          : new Date(),
      };
    }
    default:
      return {
        _tag: "FAILED",
      };
  }
};

export const getFlexpaLinkStatus = (
  link: NonNullable<
    NonNullable<GetInsuranceLinksQuery["me"]>["flexpaLinks"]
  >[0],
): InsuranceLinkStatus => {
  if (link.active && !link.lastSyncedAt) {
    if (
      dayjs().diff(link.createdAt, "minutes") < FLEXPA_LINK_WAIT_TIME_MINUTES
    ) {
      return {
        _tag: "CRAWL_IN_PROGRESS",
      };
    } else {
      return {
        _tag: "FAILED",
      };
    }
  }
  if (link.active && link.lastSyncedAt) {
    return {
      _tag: "COMPLETED",
      endedAt: new Date(link.lastSyncedAt),
    };
  }
  if (link.deactivatedReason === flexpa__LinkDeactivatedReason.EXPIRED) {
    return {
      _tag: "EXPIRED",
    };
  }
  return {
    _tag: "FAILED",
  };
};
// These are routes we do not pop a confirm dialog for closing the flow
export const insuranceUnprotectedRoutes = ["success", "alternate-insurance"];

export function closeInsuranceFlow(
  nav: unknown,
  showAlert: (alert: AlertDialog) => void,
  routeName: string,
) {
  if (!isValidFunction(nav)) {
    // There is 0 reason this should happen but we have no other way of
    // validating the nav type
    throw new Error(
      `Error routing close during insurance flow, navigator not a valid function on route: ${routeName}`,
    );
  }
  analyticsService.trackEvent("InsuranceLinkCloseStep", {
    initialRoute: routeName,
  });
  console.log(routeName);
  if (insuranceUnprotectedRoutes.includes(routeName)) {
    nav();
  } else {
    showAlert(closeInsurancePreemptiveAlert(nav));
  }
}

export function goBackInsuranceFlow(
  nav: unknown,
  showAlert: (alert: AlertDialog) => void,
  routeName: string,
) {
  if (!isValidFunction(nav)) {
    // There is 0 reason this should happen but we have no other way of
    // validating the nav type
    throw new Error(
      `ERROR: error routing back during insurance flow, navigator not a valid function on route: ${routeName}`,
    );
  }
  analyticsService.trackEvent("InsuranceLinkBackStep", {
    initialRoute: routeName,
  });
  if (insuranceUnprotectedRoutes.includes(routeName)) {
    nav();
  } else {
    showAlert(restartInsuranceAlert(nav));
  }
}

export function getErrorCopy(
  isRelinking: boolean,
  errorState: ErrorState | null,
) {
  if (isRelinking) {
    return {
      title: "Could not update your account",
      subtitle:
        "We’re unable to re-link your insurance account at the moment. Please, try again later.",
      description:
        "In the meantime, please go ahead and start a case. Our team of experts will do their best to support you with your issue.",
    };
  } else if (errorState && errorState.type === "FailedLoginMaxReached") {
    return {
      title: "Could not log in",
      subtitle: "Try resetting your password at ",
      description:
        "In the meantime, please go ahead and start a case. Our team of experts will do their best to support you with your issue.",
      insurerLink: errorState.signInUrl,
    };
  } else if (errorState?.type === "Unknown" && errorState.signInUrl) {
    return {
      title: "We had trouble logging in",
      subtitle: "Try confirming you can sign in at ",
      description:
        "In the meantime, please go ahead and start a case. Our team of experts will do their best to support you with your issue.",
      insurerLink: errorState.signInUrl,
    };
  } else {
    return {
      title: "Insurer not responding",
      subtitle: "Your insurer is taking longer than usual.",
      description:
        "Don’t worry – you can still continue setting up your account.",
    };
  }
}

import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { gql } from "@medbillai/graphql-types";

import { errorHandling } from "../../../lib/apollo/utils";
import { normalizeEobData } from "./useNormalizedEobs";

type FlaggedEobCount = {
  countFlaggedExplanationOfBenefits: number;
};

const getFlaggedEobsQuery = gql(/* GraphQL */ `
  query GetFlaggedEobs {
    queryFlaggedExplanationOfBenefits {
      id
      title
      status
      created
      resourceId
      totalsByType {
        memberLiability {
          value
          currency
        }
      }
      decision {
        text
        coding {
          code
        }
      }
      item {
        id
        productOrService {
          text
          coding {
            code
            display
          }
        }
        reviewOutcome {
          decision {
            text
          }
          reason {
            text
          }
        }
      }
      total {
        category {
          coding {
            code
          }
        }
        amount {
          currency
          value
        }
      }
      patient {
        display
      }
      billablePeriod
      provider {
        display
      }
      facility {
        display
      }
      serviceDate
      findings {
        id
        explanation
        userDismissed
        lastReviewStatus
        type
        note
      }
    }
  }
`);

const getFlaggedEobsCountQuery = gql(/* GraphQL */ `
  query GetFlaggedEobsCount {
    countFlaggedExplanationOfBenefits
  }
`);

export function useFlaggedEobs() {
  const { loading, data, error, refetch, networkStatus } = useQuery(
    getFlaggedEobsQuery,
    {
      context: errorHandling("caller"),
      fetchPolicy: "cache-and-network",
    },
  );

  const normalizedData = useMemo(() => {
    return normalizeEobData(data?.queryFlaggedExplanationOfBenefits || []);
  }, [data]);

  const totalCount = useMemo(() => normalizedData.length, [normalizedData]);

  return {
    loading,
    data: normalizedData,
    totalCount,
    networkStatus,
    refetch,
    error,
  };
}

export function useFlaggedEobsCount() {
  const { loading, data, error, refetch } = useQuery<FlaggedEobCount>(
    getFlaggedEobsCountQuery,
    {
      context: errorHandling("caller"),
      fetchPolicy: "cache-first",
    },
  );

  const count = useMemo(() => {
    return data?.countFlaggedExplanationOfBenefits ?? 0;
  }, [data]);

  return {
    loading,
    count,
    refetch,
    error,
  };
}

import React from "react";

import { ChevronRight } from "@tamagui/lucide-icons";

import {
  Card,
  Paragraph,
  Paragraph2,
  XStack,
  YStack,
  hairline,
} from "@medbillai/ui";

import { Flag } from "@medbillai/utils";

import { useBoolVariation } from "../../../lib/flag";

interface BillsOverviewBannerProps {
  issueOrBillCount?: number;
  onPress: () => void;
  issueType?: "single" | "multiple";
}

const getBannerText = (
  count: number,
  issueType: "single" | "multiple",
): string => {
  if (issueType === "single") {
    return `${count} potential ${count === 1 ? "issue" : "issues"} with this bill`;
  }
  return `${count} ${count === 1 ? "bill" : "bills"} with possible issues`;
};

export const BillsOverviewBanner = ({
  issueOrBillCount = 0,
  onPress,
  issueType = "multiple",
}: BillsOverviewBannerProps) => {
  const isFindingsEnabled = useBoolVariation(
    Flag.EOB_FINDINGS_V1_ENABLED,
    false,
  );

  if (!isFindingsEnabled || issueOrBillCount <= 0) {
    return null;
  }

  return (
    <XStack
      px={issueType === "single" ? "$4.5" : "$3"}
      width="100%"
      justifyContent="center"
    >
      <Card
        borderRadius="$6"
        onPress={onPress}
        pressStyle={{
          backgroundColor: "$backgroundTertiary",
          transform: [{ scale: 0.98 }],
        }}
        animation="200ms easeCubic"
        borderWidth={hairline}
        marginVertical={issueType === "single" ? 0 : "$3"}
        borderColor="$strokeSecondary"
        shadowColor="$shadowColor"
        shadowOpacity={0.07}
        shadowRadius={4}
        shadowOffset={{ width: 0, height: 2 }}
        width="100%"
      >
        <XStack
          justifyContent="space-between"
          alignItems="center"
          paddingVertical="$4"
          paddingHorizontal="$5"
        >
          <XStack alignItems="center" gap="$3.5" flex={1}>
            <XStack
              backgroundColor="$backgroundTertiary"
              width={42}
              height={42}
              borderRadius="$7"
              alignItems="center"
              justifyContent="center"
            >
              <Paragraph size="$6">
                {issueType === "single" ? "💸" : "💵"}
              </Paragraph>
            </XStack>
            <YStack gap="$0.5">
              <Paragraph bold>
                {getBannerText(issueOrBillCount, issueType)}
              </Paragraph>
              <Paragraph2 theme="alt1">
                Tap to review for potential savings.
              </Paragraph2>
            </YStack>
          </XStack>
          <ChevronRight size="$1" />
        </XStack>
      </Card>
    </XStack>
  );
};

import Big from "big.js";

import { type PillProps, type SelectBarTabs } from "@medbillai/ui";

import {
  type BillTotals,
  type EOB,
  type EOBTotalsByType,
  type Money,
  type Period,
} from "./types";

export const parsePeriod = (periodStr: string | null | undefined) => {
  if (!periodStr) {
    return;
  }
  return JSON.parse(JSON.stringify(periodStr)) as Period;
};

export function parseTotalsByType(totalsByType: EOBTotalsByType): BillTotals {
  const result: BillTotals = {};
  Object.entries(totalsByType).forEach(([key, value]) => {
    if (value?.value) {
      result[key as keyof EOBTotalsByType] = {
        amount: Big(value.value),
        currency: value.currency ?? "USD",
      };
    }
  });
  return result;
}

export function parseBillTotals(
  totalsByType: EOB["totalsByType"],
): BillTotals | null {
  if (totalsByType) {
    return parseTotalsByType(totalsByType);
  }
  return null;
}

export function parseStillOwed(totals: BillTotals): Money {
  return {
    amount: (totals.memberLiability?.amount ?? Big(0)).minus(
      totals.paidByPatient?.amount ?? Big(0),
    ),
    currency:
      totals.memberLiability?.currency ??
      totals.paidByPatient?.currency ??
      "USD",
  };
}

export function formatCostTotal(item?: Money, empty = "-", inverse?: boolean) {
  if (!item || !item.amount) {
    return empty;
  }

  const roundedAmount = item.amount.round(0, Big.roundDown).abs();
  let negative = item.amount.lt(0);
  if (inverse) {
    negative = !negative;
  }

  const formattedNumber = new Intl.NumberFormat().format(
    roundedAmount.toNumber(),
  );

  // TODO: Handle currency
  return `${negative ? "-" : ""}$${formattedNumber}`;
}

export const pageSize = 15;

export const allFilter = {
  first: pageSize,
  after: 0,
  denied: false,
  outOfNetwork: false,
  inNetwork: false,
};

export const deniedFilter = {
  first: pageSize,
  after: 0,
  denied: true,
  outOfNetwork: false,
  inNetwork: false,
};

export const inNetworkFilter = {
  first: pageSize,
  after: 0,
  denied: false,
  outOfNetwork: false,
  inNetwork: true,
};

export const outOfNetworkFilter = {
  first: pageSize,
  after: 0,
  denied: false,
  outOfNetwork: true,
  inNetwork: false,
};

export const filterOptionIds = {
  all: "all",
  denied: "denied",
  inNetwork: "in-network",
  outOfNetwork: "out-of-network",
};

export const filterOptions: SelectBarTabs = [
  { id: filterOptionIds.all, name: "All" },
  { id: filterOptionIds.denied, name: "Denied" },
  { id: filterOptionIds.inNetwork, name: "In network" },
  { id: filterOptionIds.outOfNetwork, name: "Out of network" },
];

export const filterMap = {
  [filterOptionIds.all]: allFilter,
  [filterOptionIds.denied]: deniedFilter,
  [filterOptionIds.inNetwork]: inNetworkFilter,
  [filterOptionIds.outOfNetwork]: outOfNetworkFilter,
};

export const getBillPillStyle = (
  status: string | undefined | null,
): PillProps => {
  switch (status) {
    case "in":
      return {
        text: "In Network",
        theme: "green",
      };
    case "out":
      return {
        text: "Out Of Network",
        theme: "orange",
      };
    case "pending":
      return {
        text: "Pending",
        theme: "lightBlue",
      };
    case "approved":
      return {
        text: "Approved",
        theme: "green",
      };
    case "denied":
      return {
        text: "Denied",
        theme: "red",
      };
    case "partial":
      return {
        text: "Denied",
        theme: "orange",
      };
    default:
      return {
        text: "Unknown",
        theme: "lightBlue",
      };
  }
};

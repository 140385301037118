export type SingularConfigProps = {
  singularSdkKey: string;
  singularSdkSecret: string;
  singularFacebookAppId: string;
  singularTrackingEnabled?: boolean;
};

export type SerializableValue =
  | boolean
  | number
  | string
  | null
  | SerializableArray
  | SerializableObject;
export type SerializableArray = Array<SerializableValue>;
export type SerializableObject = {
  [key in string | number]: SerializableValue;
};

// https://support.singular.net/hc/en-us/articles/6568295258395-React-Native-SDK-List-of-Standard-Events-and-Attributes
// List of standard events with clarifications commenting out unused ones.
// Will add notes and notion guide for creating custom events down the road.
export const SingularEvents = {
  // sngRate: "sng_rate", // Event when a user rates content or app
  // sngSpentCredits: "sng_spent_credits", // Event for spending virtual credits
  onboardingCompleted: "sng_tutorial_complete", // Event for completing onboarding
  onboardingStarted: "sng_tutorial_start", // Event for starting onboarding
  loginCompleted: "sng_login", // Event when a user logs into the app
  // sngStartTrial: "sng_start_trial", // Event for starting a trial subscription
  // sngSubscribe: "sng_subscribe", // Event when a user subscribes to a service
  // sngBook: "sng_book", // Event for booking an item (e.g., ticket, reservation)
  // sngContentViewList: "sng_content_view_list", // Event for viewing a list of content items
  // sngInvite: "sng_invite", // Event for inviting another user to the app
  // sngShare: "sng_share", // Event when content is shared
  // sngSubmitApplication: "sng_submit_application", // Event for submitting an application (e.g., job, form)
  // sngUpdate: "sng_update", // Event when the app or content is updated
  // sngEcommercePurchase: "sng_ecommerce_purchase", // Event for completing an e-commerce purchase
  // sngViewCart: "sng_view_cart", // Event when viewing items in the cart
  // sngAchievementUnlocked: "sng_achievement_unlocked", // Event for unlocking an achievement
  // sngAddPaymentInfo: "sng_add_payment_info", // Event for adding payment information
  // sngAddToCart: "sng_add_to_cart", // Event when an item is added to the cart
  // sngAddToWishlist: "sng_add_to_wishlist", // Event when an item is added to the wishlist
  // sngCheckoutInitiated: "sng_checkout_initiated", // Event for starting the checkout process
  completeRegistration: "sng_complete_registration", // Event when a new user completes registration
  // sngContentView: "sng_content_view", // Event for viewing a single piece of content
  // sngLevelAchieved: "sng_level_achieved", // Event for reaching a certain level in the app
  // sngSearch: "sng_search", // Event when a search is performed
  openBill: "open_bill",
  createCase: "create_case",
  insuranceLinkStart: "insurance_link_start",
  insuranceLinkSuccess: "insurance_link_success",
};
export type SingularEventName =
  (typeof SingularEvents)[keyof typeof SingularEvents];

// List of standard event attributes with clarifications
// This will likely evolve to make this easier but leaving the complete defualt
// list at the momment until we knwo what we want to be tracking.
export const SingularEventAttributes = {
  sngAttrFromDate: "sng_attr_from_date", // Start date attribute (e.g., for bookings)
  sngAttrToDate: "sng_attr_to_date", // End date attribute (e.g., for bookings)
  sngAttrAchievementId: "sng_attr_achievement_id", // ID for specific achievements
  sngAttrContent: "sng_attr_content", // Content name or type attribute
  sngAttrContentId: "sng_attr_content_id", // Unique identifier for a piece of content
  sngAttrContentList: "sng_attr_content_list", // List of content IDs
  sngAttrContentType: "sng_attr_content_type", // Type of content (e.g., video, article)
  sngAttrCouponCode: "sng_attr_coupon_code", // Code for discounts or promotions
  sngAttrDeepLink: "sng_attr_deep_link", // URL used for deep linking
  sngAttrEventEnd: "sng_attr_event_end", // End time for events or sessions
  sngAttrEventStart: "sng_attr_event_start", // Start time for events or sessions
  sngAttrHotelScore: "sng_attr_hotel_score", // Rating for a hotel (or similar service)
  sngAttrItemDescription: "sng_attr_item_description", // Description of an item
  sngAttrItemPrice: "sng_attr_item_price", // Price of an item
  sngAttrLatitude: "sng_attr_latitude", // Latitude for location-based events
  sngAttrLevel: "sng_attr_level", // Level attribute (e.g., in a game or app)
  sngAttrLocation: "sng_attr_location", // General location attribute
  sngAttrLocationAddressCountry: "sng_attr_location_address_country", // Country in location address
  sngAttrLocationAddressRegionOrProvince:
    "sng_attr_location_address_region_or_province", // State/region in address
  sngAttrLocationAddressStreet: "sng_attr_location_address_street", // Street name in location address
  sngAttrLongitude: "sng_attr_longitude", // Longitude for location-based events
  sngAttrMax: "sng_attr_max", // Maximum value attribute (e.g., score, quantity)
  sngAttrNewVersion: "sng_attr_new_version", // Version attribute for updates
  sngAttrOrigin: "sng_attr_origin", // Origin point (e.g., source of traffic or session start)
  sngAttrPaymentInfoAvailable: "sng_attr_payment_info_available", // Whether payment info is available
  sngAttrQuantity: "sng_attr_quantity", // Quantity attribute (e.g., items purchased)
  sngAttrRating: "sng_attr_rating", // User rating (e.g., for reviews)
  sngAttrRegistrationMethod: "sng_attr_registration_method", // Method of registration (e.g., social login)
  sngAttrReviewText: "sng_attr_review_text", // Text of a user review
  sngAttrScore: "sng_attr_score", // Score attribute (e.g., in games or ratings)
  sngAttrSearchString: "sng_attr_search_string", // Text of a search query
  sngAttrSubscriptionId: "sng_attr_subscription_id", // ID of a subscription
  sngAttrSuccess: "sng_attr_success", // Indicates success (true/false)
  sngAttrTransactionId: "sng_attr_transaction_id", // Unique ID for a transaction
  sngAttrTutorialId: "sng_attr_tutorial_id", // ID for specific tutorial sessions
  sngAttrValid: "sng_attr_valid", // Validity attribute (true/false)
} as const;

export type SingularEventArgs = Partial<
  Record<keyof typeof SingularEventAttributes, SerializableValue>
>;

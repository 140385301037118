import type { IconProps } from "@tamagui/helpers-icon";
import { Check as CheckIcon, X as XIcon } from "@tamagui/lucide-icons";

import {
  Ellipsis as EllipsisIcon,
  type ThemeName,
  XStack,
  type XStackProps,
  hairline,
} from "@medbillai/ui";

interface BillListItemIcon {
  status: string | null;
  wrapperProps?: XStackProps;
  iconProps?: IconProps;
}

// Default to pending
const getCircleIcon = (status: string | null, iconProps?: IconProps) => {
  switch (status) {
    case "approved":
      return (
        <CheckIcon color="$color" size="$1" strokeWidth={1.75} {...iconProps} />
      );
    case "denied":
      return (
        <XIcon color="$color" size="$1" strokeWidth={1.75} {...iconProps} />
      );
    default:
      return (
        <EllipsisIcon color="$color" size="$1" strokeWidth={2} {...iconProps} />
      );
  }
};

const getTheme = (status: string | null): ThemeName => {
  switch (status) {
    case "approved":
      return "green";
    case "denied":
      return "red";
    default:
      return "lightBlue";
  }
};

export function BillListItemIcon({
  status,
  wrapperProps,
  iconProps,
}: BillListItemIcon) {
  return (
    <XStack
      theme={getTheme(status)}
      alignItems="center"
      // No matching token
      br={12}
      jc="center"
      bg="$color3"
      width="$4"
      height="$4"
      borderWidth={hairline}
      borderColor="$color4"
      {...wrapperProps}
    >
      {getCircleIcon(status, iconProps)}
    </XStack>
  );
}
